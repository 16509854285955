<script setup lang="ts">
// vuetify
import { useDisplay } from 'vuetify'

// types
import type {
  Nullable,
  OrbitApiRequestPayload,
  Parent,
  Student,
  User
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// utils
import { isEmpty } from '@revolutionprep/utils'

// composables
import { useEnrollmentWizard } from '@/composables/enrollmentWizard'
import { usePage } from '@/composables/page'

// stores
import { useGlobalStore } from '@/store/global'

/**
 * page metadata
 * ==================================================================
 */
definePageMeta({
  name: 'EnrollmentWizardStep1Page',
  layout: 'enrollment-wizard',
  middleware: [
    'enrollment-wizard',
    'validate-route-roles'
  ]
})

/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $toast, $users } = useNuxtApp()

/**
 * vuetify
 * ==================================================================
 */
const { smAndUp, xs } = useDisplay()

/**
 * state
 * ==================================================================
 */
const title = ref('Enrollment Wizard | Step 1')
const titleIcon = ref('')
const hasDuplicateEmailError = ref(false)
const isLoading = ref(true)
const parentPayload = ref<Nullable<OrbitApiRequestPayload>>(null)
const studentPayload = ref<Nullable<OrbitApiRequestPayload>>(null)

// password form state
const passwordForm = ref({})
const isPasswordFormValid = ref(false)
const shouldValidatePasswordForm = ref(false)

/**
 * composables
 * ==================================================================
 */
const {
  affiliateReservation,
  affiliateReservationNumber,
  companyPhone,
  error,
  isProcessing,
  onIntersectSidebarBottom,
  onIntersectSidebarTop,
  sidebarCssClasses,
  step1Url,
  studentFullName,
  toNextStep,
  toolbarCssClasses
} = useEnrollmentWizard()

const { doHandleError } = useErrorHandler()

usePage(title)

/**
 * stores
 * ==================================================================
 */
const countriesStore = useCountriesStore()

const globalStore = useGlobalStore()
const { pageTitle, pageTitleIcon } = storeToRefs(globalStore)

const gradeLevelsStore = useGradeLevelsStore()
const { gradeLevels } = storeToRefs(gradeLevelsStore)

const parentStore = useParentStore()
const { parent } = storeToRefs(parentStore)

const pendingSchoolStore = usePendingSchoolStore()
const { pendingSchool } = storeToRefs(pendingSchoolStore)

const propertiesStore = usePropertiesStore()

const stateStore = useStateStore()

const studentStore = useStudentStore()
const { student } = storeToRefs(studentStore)

const timeZoneStore = useTimeZoneStore()

/**
 * form validation
 * ==================================================================
 */
const { errors, setFieldError, validate } = useForm()

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value as Student
})

const userId = computed(() => {
  return actor.value?.userId
})

/**
 * methods
 * ==================================================================
 */
async function doSubmit () {
  try {
    isProcessing.value = true
    shouldValidatePasswordForm.value = true
    const { errors } = await validate()
    if (
      !isEmpty(errors) ||
      Boolean(
        actor.value?.requireSetPassword &&
        !isPasswordFormValid.value
      )
    ) {
      $toast.error('The form is invalid. Please correct the fields in red.')
      if (xs.value) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
      return
    }
    // update parent
    if (
      parentPayload.value &&
      Object.keys(parentPayload.value || {}).length &&
      parent.value?.id
    ) {
      await parentStore.update(
        parent.value.id,
        { parent: parentPayload.value },
        {
          params: {
            include: 'address'
          }
        }
      )
    }
    // update student
    if (
      studentPayload.value &&
      Object.keys(studentPayload.value || {}).length &&
      student.value?.id
    ) {
      await studentStore.update(
        student.value.id,
        { student: studentPayload.value },
        {
          params: {
            include: 'school.address'
          }
        }
      )
      await $actor.core.doRefetch()
    }
    // update password
    if (
      Object.keys(passwordForm.value).length &&
      userId.value &&
      student.value?.id
    ) {
      const { user: _user } = await $users.update<{ user: User }>(
        `${userId.value}/change_password`,
        { ...passwordForm.value },
        { method: 'PUT' }
      )
      $actor.core.setUser(_user)
      await studentStore.update(
        student.value.id,
        { student: { requireSetPassword: false } },
        {
          params: {
            include: 'school.address'
          }
        }
      )
      await $actor.core.doRefetch()
    } else if (!userId.value) {
      throw new Error('User ID not found. Please refresh the page.')
    } else if (!student.value?.id) {
      throw new Error('Student ID not found. Please refresh the page.')
    }
    toNextStep(1)
  } catch (errorResponse) {
    const errorObject =
      await doHandleError(errorResponse as FetchError | Error)
    if (errorObject?.statusCode === 500) {
      error.value = errorObject
    }
    if (errorObject?.message === 'User email has already been taken.') {
      hasDuplicateEmailError.value = true
    }
  } finally {
    isProcessing.value = false
    shouldValidatePasswordForm.value = false
  }
}

function handlePasswordFormErrors (_errors: { [key: string]: string }) {
  setFieldError('password', _errors.password || undefined)
  setFieldError('passwordConfirmation', _errors.passwordConfirmation || undefined)
}

function setParentPayload (prop: OrbitApiRequestPayload) {
  parentPayload.value = {
    ...parentPayload.value,
    ...prop
  }

  if (prop?.phone) {
    // if user is updating phone number, reset sms opt in properties
    parent.value = {
      ...parent.value,
      ...parentPayload.value,
      smsOptInAt: null,
      smsOptInMedium: null,
      smsOptInType: null
    } as Parent
  }
}

function setPendingSchool () {
  if (student.value?.school?.type === 'PendingSchool') {
    pendingSchool.value = student.value?.school
  }
}

function setStudentPayload (prop: OrbitApiRequestPayload) {
  studentPayload.value = {
    ...studentPayload.value,
    ...prop
  }
}

/**
 * page setup
 * ==================================================================
 */
pageTitle.value = title.value
pageTitleIcon.value = titleIcon.value

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  // set stepper
  $actor.core.storage.setUniversal('enrollmentWizardStep', 1)
  $actor.core.storage.setUniversal(
    'enrollmentWizardHeading',
    'Step 1: Confirm account information'
  )
})

onMounted(async () => {
  try {
    await Promise.all([
      countriesStore.index(),
      gradeLevelsStore.index({
        params: {
          withGradYear: true
        }
      }),
      propertiesStore.index(),
      stateStore.index(),
      timeZoneStore.index()
    ])
    setPendingSchool()
  } catch (errorResponse) {
    const errorObject =
      await doHandleError(errorResponse as FetchError | Error)
    if (errorObject?.statusCode === 500) {
      error.value = errorObject
    }
  } finally {
    isLoading.value = false
  }
})
</script>

<template>
  <div>
    <v-container class="px-0">
      <v-row v-if="isLoading">
        <div
          class="d-flex flex-column justify-space-between"
          style="width: 100%;"
        >
          <v-skeleton-loader
            type="heading"
            style="padding: 12px;"
          />
          <v-skeleton-loader
            class="pe-4"
            width="100%"
            type="article, article, article, article"
          />
        </div>
      </v-row>
      <v-row v-else-if="error">
        <v-col>
          <lazy-r-error-display
            :error="error"
            :home-url="step1Url"
          />
        </v-col>
      </v-row>
      <template v-else>
        <v-row>
          <v-col
            v-if="xs && !isEmpty(errors)"
            cols="12"
            sm="5"
            md="4"
            lg="3"
          >
            <lazy-r-card-validation-errors :validation-errors="errors" />
          </v-col>
          <v-col
            cols="12"
            sm="7"
            md="8"
            lg="9"
          >
            <v-alert
              class="mb-4"
              type="info"
              variant="tonal"
            >
              Please confirm your information.
            </v-alert>
            <v-card class="mb-6">
              <v-card-title>
                Parent information
              </v-card-title>
              <v-divider />
              <LazyFormEditParent
                v-if="parent"
                :parent-data="parent"
                @set-parent-payload="setParentPayload"
              />
            </v-card>
            <v-card class="mb-6">
              <v-card-title>
                Student information
              </v-card-title>
              <v-divider />
              <LazyFormEditStudent
                v-if="student && affiliateReservationNumber"
                :affiliate-reservation-number="affiliateReservationNumber"
                :company-phone="companyPhone"
                :grade-levels="gradeLevels"
                :has-duplicate-email-error="hasDuplicateEmailError"
                :student-data="student"
                :pending-school-data="pendingSchool"
                @input-email="hasDuplicateEmailError = false"
                @set-student-payload="setStudentPayload"
              />
            </v-card>
            <v-card v-if="actor?.requireSetPassword">
              <v-card-title>
                Account Information
              </v-card-title>
              <v-divider />
              <v-card-subtitle class="account-information text-textgrey mt-3">
                This is the login information you and your student will use,
                choose your password accordingly.
              </v-card-subtitle>
              <FormEditPassword
                :show-actions="false"
                :should-validate="shouldValidatePasswordForm"
                @errors="handlePasswordFormErrors"
                @toggle-valid="isPasswordFormValid = $event"
                @update-form="passwordForm = $event"
              >
                <template #username-caption>
                  <v-card-subtitle class="account-information text-textgrey ps-2">
                    Your username will be automatically generated.
                  </v-card-subtitle>
                </template>
              </FormEditPassword>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="5"
            md="4"
            lg="3"
          >
            <div v-intersect="onIntersectSidebarTop" />
            <div :class="sidebarCssClasses">
              <LazyCardReservationDetails
                v-if="affiliateReservation"
                class="mb-2"
                :affiliate-reservation="affiliateReservation"
                reservation-title="Back-Up Care Reservation"
                :student-full-name="studentFullName"
              />
              <lazy-r-card-validation-errors
                v-if="smAndUp && !isEmpty(errors)"
                class="mt-6"
                :validation-errors="errors"
              />
              <div v-intersect="onIntersectSidebarBottom" />
              <LazyToolbarEnrollmentWizard
                :class="toolbarCssClasses()"
                :has-padding="Boolean(toolbarCssClasses())"
                :is-flat="!Boolean(toolbarCssClasses())"
                :show-back-button="false"
                :is-next-button-disabled="isProcessing"
                @next="doSubmit"
              />
            </div>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<style scoped>
.account-information {
  white-space: normal;
}
</style>
